var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"tip":"Please wait...","spinning":_vm.loading}},[_c('div',{staticClass:"spin-content"},[_c('div',[_c('div',{staticClass:"card",class:_vm.$style.container},[_c('div',{staticClass:"text-dark font-size-24 mb-4"},[_c('strong',[_vm._v("Sign in to your account")]),(_vm.error)?_c('p',{staticClass:"text-red font-size-16"},[_vm._v(_vm._s(_vm.error))]):_vm._e()]),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: 'Please enter your email' },
                  ],
                },
              ]),expression:"[\n                'email',\n                {\n                  initialValue: '',\n                  rules: [\n                    { required: true, message: 'Please enter your email' },\n                  ],\n                },\n              ]"}],attrs:{"size":"large","placeholder":"Email"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your Password!',
                    },
                  ],
                },
              ]),expression:"[\n                'password',\n                {\n                  initialValue: '',\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter your Password!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"size":"large","placeholder":"Password","type":"password"}})],1),_c('a-button',{staticClass:"text-center w-100",attrs:{"type":"primary","htmlType":"submit","size":"large","loading":_vm.loading}},[_c('strong',[_vm._v("Sign in")])])],1),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/forgot-password"}},[_vm._v("Forgot Password?")])],1),_c('div',{staticClass:"text-center pt-2 mb-auto"},[_c('span',{staticClass:"mr-2"},[_vm._v("Don't have an account?")]),_c('div',[_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/register"}},[_vm._v("Register as Visitor")])],1),_c('div',[_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/register-entity"}},[_vm._v("Register as Service Provider")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }