<template>
  <a-spin tip="Please wait..." :spinning="loading">
    <div class="spin-content">
      <div>
        <div class="card" :class="$style.container">
          <div class="text-dark font-size-24 mb-4">
            <strong>Sign in to your account</strong>
            <p class="text-red font-size-16" v-if="error">{{ error }}</p>
          </div>
          <a-form class="mb-4" :form="form" @submit="handleSubmit">
            <a-form-item>
              <a-input
                size="large"
                placeholder="Email"
                v-decorator="[
                  'email',
                  {
                    initialValue: '',
                    rules: [
                      { required: true, message: 'Please enter your email' },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item>
              <a-input
                size="large"
                placeholder="Password"
                type="password"
                v-decorator="[
                  'password',
                  {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your Password!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-button
              type="primary"
              htmlType="submit"
              size="large"
              class="text-center w-100"
              :loading="loading"
            >
              <strong>Sign in</strong>
            </a-button>
          </a-form>
          <router-link
            to="/auth/forgot-password"
            class="kit__utils__link font-size-16"
            >Forgot Password?</router-link
          >
        </div>
        <div class="text-center pt-2 mb-auto">
          <span class="mr-2">Don't have an account?</span>
          <div>
            <router-link
              to="/auth/register"
              class="kit__utils__link font-size-16"
              >Register as Visitor</router-link
            >
          </div>

          <div>
            <router-link
              to="/auth/register-entity"
              class="kit__utils__link font-size-16"
              >Register as Service Provider</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { AUTH_LOGIN } from "@/store/actions";

export default {
  name: "Login",
  computed: {
    ...mapState(["settings"]),
  },
  data() {
    return {
      loading: false,
      error: "",
      form: this.$form.createForm(this),
    };
  },
  async created() {
    if (this.$route.query.token) {
      const token = this.$route.query.token;
      this.$router.replace({ query: {} });
      await this.$store.dispatch("auth/CONFIRM_ACCOUNT", {
        payload: { token },
      });
    }
  },
  methods: {
    ...mapActions("auth", {
      login: AUTH_LOGIN,
    }),
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          try {
            this.initiateLogin(values);
          } catch (e) {
            console.log(e);
          }
        }
      });
    },
    async initiateLogin({ email, password }) {
      try {
        this.loading = true;
        this.error = "";

        const loginResponse = await this.login({ email, password });

        if (loginResponse.authorized && loginResponse.token) {
          this.$notification.success({ message: "Logged in successfully" });
          if (this.$route.query.redirect) {
            return this.$router
              .push(this.$route.query.redirect)
              .catch(() => {});
          }
          this.$router
            .push(`/${this.getUserAccountProfileModule}`)
            .catch(() => {});
        } else {
          throw new Error(
            "There was an issue with login. Please try again later."
          );
        }
      } catch (error) {
        this.error = error.message;
        this.$notification.error({ message: error.message || "Login Failed" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
